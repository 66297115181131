import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { service } from '@ember/service';
import { pluralize } from 'ember-inflector';
import { underscore } from '@ember/string';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import config from '../../config/environment';
import type SessionService from '../../services/onward-session';
import type SettingsService from '../../services/settings';
import { baseNamespace } from '../../utils/adapter-namespaces';

export default class OnwardJSONAPIAdapter extends JSONAPIAdapter {
  @service declare router: RouterService;
  @service('onward-session') declare session: SessionService;
  @service declare settings: SettingsService;

  get host() {
    // We want to use environment variables for local development.
    if (this.settings.getEnvironment() === 'localhost') {
      return config.apiHost;
    }

    const apiHost = this.settings.getValue('API_URL');
    return apiHost === undefined ? '' : apiHost;
  }

  namespace = baseNamespace;

  get headers() {
    const headers: Record<string, string> = {};

    headers['Accept'] = 'application/json';
    headers['Content-Type'] = 'application/json';

    if (this.session.isAuthenticated) {
      const { accessToken, client, uid } = this.session.data.authenticated;

      if (accessToken) {
        headers['access-token'] = accessToken;
      }

      if (client) {
        headers['client'] = client;
      }
      headers['token-type'] = 'Bearer';
      headers['tz-name'] = this.session.systemTimeZone;

      if (uid) {
        headers['uid'] = uid;
      }

      const { user } = this.session.impersonationContext;

      if (user) {
        headers['custodian-context'] = user.id;
      }
    }

    return headers;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Need to figure out how to properly type this, but it should be
  // one of the models in the ModelRegistry.
  pathForType(type: string) {
    return pluralize(underscore(type));
  }

  handleResponse(
    status: number,
    headers: Record<string, string>,
    payload: object,
    requestData: object,
  ) {
    if (status === 401 && this.session.isAuthenticated) {
      return this.router.transitionTo('sign-out');
    }

    const responseObject = super.handleResponse(
      status,
      headers,
      payload,
      requestData,
    );

    // This will add the adapter error to the response.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (responseObject && responseObject.isAdapterError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      responseObject.httpErrorResponse = {
        status,
        headers,
        payload,
      };
    }

    return responseObject;
  }

  _convertQueryParams(query: Record<string, string | number | boolean>) {
    const updatedQuery: typeof query = {};

    Object.keys(query).forEach((key) => {
      const value = query[key];

      if (value !== undefined && value !== null) {
        updatedQuery[underscore(key)] = value;
      }
    });

    return updatedQuery;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Need to figure out how to properly type this, but `type` should
  // be one of the models in the ModelRegistry.
  query(
    store: Store,
    type: string,
    query: Record<string, string | number | boolean>,
  ) {
    const updatedQuery = this._convertQueryParams(query);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Need to figure out how to properly type this, but `type` should
    // be one of the models in the ModelRegistry.
    return super.query(store, type, updatedQuery);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Need to figure out how to properly type this, but `type` should
  // be one of the models in the ModelRegistry.
  queryRecord(
    store: Store,
    type: string,
    query: Record<string, string | number | boolean>,
  ) {
    const updatedQuery = this._convertQueryParams(query);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Need to figure out how to properly type this, but `type` should
    // be one of the models in the ModelRegistry.
    return super.queryRecord(store, type, updatedQuery);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'onward-jsonapi': OnwardJSONAPIAdapter;
  }
}
