import { template as template_ded50772069b45ea85ea651416a58857 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import { gt } from 'ember-truth-helpers';
import set from 'ember-set-helper/helpers/set';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - We need to figure out how to create the types for this.
import sortBy from 'ember-composable-helpers/helpers/sort-by';
import AccountFilterComponent, { type AccountFilter, type AccountType } from '../filters/account-filter';
import type { DateGroupingOption, ReportingFilterDateRange, ReportingDateRangeOption } from '../../utils/filters/date-range';
import { dateRanges } from './reporting-date-range-filter';
import ReportingDateRangeFilter from './reporting-date-range-filter';
import FinancialResponsibilityFilter from '../filters/financial-responsibility-filter';
import LocationFilter from '../filters/location-filter';
import type { Location } from '../filters/location-filter';
import RideStatusFilter from '../filters/ride-status-filter';
import type ReportingFiltersService from '../../services/reporting-filters';
import type SessionService from '../../services/onward-session';
import UiButton from '@onwardcare/ui-components/components/ui-button';
import UiForm from '@onwardcare/ui-components/components/ui-form';
import UiSlideover from '../ui-slideover';
export type Filters = {
    accountIds: string[];
    accountType: AccountType | null;
    dateRange: ReportingFilterDateRange;
    financialResponsibilities: string[];
    geoId: string | null;
    regionIds: string[];
    rideCompletionStatuses: string[];
    transportPartnerIds: string[];
    transportTypeIds: string[];
    zipcodeIds: string[];
};
export interface FiltersSignature {
    Element: HTMLDivElement;
    Args: {
        filters: Filters;
        onChange: (filters: Filters) => void;
    };
    Blocks: EmptyObject;
}
export default class FiltersComponent extends Component<FiltersSignature> {
    @service
    reportingFilters: ReportingFiltersService;
    @service
    router: RouterService;
    @service('onward-session')
    session: SessionService;
    @tracked
    isOpen = false;
    @tracked
    selectedDateRange: ReportingDateRangeOption | null = null;
    @tracked
    previousDateRange: ReportingDateRangeOption | null = null;
    @tracked
    selectedDateGrouping: DateGroupingOption | null = null;
    @tracked
    previousDateGrouping: DateGroupingOption | null = null;
    @tracked
    accountFilter: AccountFilter = {
        accountIds: [],
        accountType: null
    };
    @tracked
    previousAccountFilter: AccountFilter = {
        accountIds: [],
        accountType: null
    };
    @tracked
    selectedFinancialResponsibilities: string[] = [];
    @tracked
    previousFinancialResponsibilities: string[] = [];
    @tracked
    selectedGeo: string | null = null;
    @tracked
    previousGeo: string | null = null;
    @tracked
    selectedRegions: string[] = [];
    @tracked
    previousRegions: string[] = [];
    @tracked
    selectedRideStatuses: string[] = [];
    @tracked
    previousRideStatuses: string[] = [];
    @tracked
    selectedTransportTypes: string[] = [];
    @tracked
    previousTransportTypes: string[] = [];
    @tracked
    selectedTransportPartners: string[] = [];
    @tracked
    previousTransportPartners: string[] = [];
    @tracked
    selectedZipcodes: string[] = [];
    @tracked
    previousZipcodes: string[] = [];
    get activeFilterCount() {
        let count = 0;
        if (this.accountFilter?.accountType) {
            count++;
        }
        if (this.accountFilter?.accountIds && this.accountFilter.accountIds.length > 0) {
            count++;
        }
        if (this.selectedDateRange?.id !== this.reportingFilters.defaultDatePeriod) {
            count++;
        }
        // This only works now because all date ranges that have the "week" for the
        // grouping option don't display, so this will only be different for date
        // ranges that have "Month" and "Quarter" as options.
        if (this.selectedDateGrouping?.id !== this.reportingFilters.defaultDateGrouping) {
            count++;
        }
        if (this.selectedFinancialResponsibilities.length > 0) {
            count++;
        }
        if (this.selectedTransportTypes.length > 0) {
            count++;
        }
        if (this.selectedTransportPartners.length > 0) {
            count++;
        }
        if (this.selectedRideStatuses.length > 0) {
            count++;
        }
        if (this.selectedGeo) {
            count++;
        }
        if (this.selectedRegions.length > 0) {
            count++;
        }
        if (this.selectedZipcodes.length > 0) {
            count++;
        }
        return count;
    }
    get transportTypesDisplay() {
        const transportTypes = this.session.viewableTransportTypes.filter((transportType)=>this.selectedTransportTypes.includes(transportType.id));
        return transportTypes ? transportTypes.map((transportType)=>transportType.fullName).join(', ') : null;
    }
    get transportPartnersDisplay() {
        const transportPartners = this.session.viewableTransportPartners.filter((transportPartner)=>this.selectedTransportPartners.includes(transportPartner.id));
        return transportPartners ? transportPartners.map((transportPartner)=>transportPartner.name).join(', ') : null;
    }
    constructor(owner: unknown, args: FiltersSignature['Args']){
        super(owner, args);
        this.accountFilter = {
            accountIds: this.args.filters.accountIds,
            accountType: this.args.filters.accountType
        };
        this.previousAccountFilter = this.accountFilter;
        this.selectedFinancialResponsibilities = this.args.filters.financialResponsibilities;
        this.previousFinancialResponsibilities = this.selectedFinancialResponsibilities;
        this.selectedGeo = this.args.filters.geoId;
        this.previousGeo = this.selectedGeo;
        this.selectedRegions = this.args.filters.regionIds;
        this.previousRegions = this.selectedRegions;
        this.selectedRideStatuses = this.args.filters.rideCompletionStatuses;
        this.previousRideStatuses = this.selectedRideStatuses;
        this.selectedTransportTypes = this.args.filters.transportTypeIds;
        this.previousTransportTypes = this.selectedTransportTypes;
        this.selectedTransportPartners = this.args.filters.transportPartnerIds;
        this.previousTransportPartners = this.selectedTransportPartners;
        this.selectedZipcodes = this.args.filters.zipcodeIds;
        this.previousZipcodes = this.selectedZipcodes;
        this.initializeDateRangeFilter();
    }
    initializeDateRangeFilter() {
        const range = dateRanges.find((range)=>{
            return range.id === this.args.filters.dateRange.datePeriod;
        });
        if (range) {
            this.selectedDateRange = range;
        } else {
            this.selectedDateRange = dateRanges.find((range)=>range.id === this.reportingFilters.defaultDatePeriod) ?? null;
        }
        this.previousDateRange = this.selectedDateRange;
        const dateGrouping = this.args.filters.dateRange.dateGrouping;
        let selectedDateGrouping;
        if (dateGrouping) {
            const grouping = this.selectedDateRange?.dateGroupingOptions.find((grouping)=>grouping.id === dateGrouping);
            if (grouping) {
                selectedDateGrouping = grouping;
            }
        }
        if (!selectedDateGrouping) {
            // TODO: We probably need to figure out how to set the default date grouping
            //       for a date range. Right now, it just works out that the first
            //       option is the one we want.
            selectedDateGrouping = this.selectedDateRange?.dateGroupingOptions[0] ?? null;
        }
        this.selectedDateGrouping = selectedDateGrouping;
    }
    @action
    openMenu() {
        this.previousAccountFilter = this.accountFilter;
        this.previousDateGrouping = this.selectedDateGrouping;
        this.previousDateRange = this.selectedDateRange;
        this.previousFinancialResponsibilities = this.selectedFinancialResponsibilities;
        this.previousGeo = this.selectedGeo;
        this.previousRegions = this.selectedRegions;
        this.previousRideStatuses = this.selectedRideStatuses;
        this.previousTransportPartners = this.selectedTransportPartners;
        this.previousTransportTypes = this.selectedTransportTypes;
        this.previousZipcodes = this.selectedZipcodes;
        this.isOpen = true;
    }
    @action
    closeMenu() {
        this.isOpen = false;
        this.accountFilter = this.previousAccountFilter;
        this.selectedDateGrouping = this.previousDateGrouping;
        this.selectedDateRange = this.previousDateRange;
        this.selectedFinancialResponsibilities = this.previousFinancialResponsibilities;
        this.selectedGeo = this.previousGeo;
        this.selectedRegions = this.previousRegions;
        this.selectedRideStatuses = this.previousRideStatuses;
        this.selectedTransportPartners = this.previousTransportPartners;
        this.selectedTransportTypes = this.previousTransportTypes;
        this.selectedZipcodes = this.previousZipcodes;
    }
    @action
    resetFilters() {
        const defaultDateRange = dateRanges.find((range)=>range.id === this.reportingFilters.defaultDatePeriod) ?? null;
        const defaultDateGrouping = defaultDateRange?.dateGroupingOptions.find((option)=>option.id === this.reportingFilters.defaultDateGrouping) ?? null;
        this.accountFilter = {
            accountIds: [],
            accountType: null
        };
        this.selectedDateGrouping = defaultDateGrouping;
        this.selectedDateRange = defaultDateRange;
        this.selectedFinancialResponsibilities = [];
        this.selectedGeo = null;
        this.selectedRegions = [];
        this.selectedRideStatuses = [];
        this.selectedTransportPartners = [];
        this.selectedTransportTypes = [];
        this.selectedZipcodes = [];
    }
    @action
    dateRangeSelected(dateRange: ReportingDateRangeOption | null, dateGrouping: DateGroupingOption | null) {
        this.selectedDateRange = dateRange;
        this.selectedDateGrouping = dateGrouping;
    }
    @action
    locationSelected(location: Location) {
        this.selectedGeo = location.geoId;
        this.selectedRegions = location.regionIds;
        this.selectedZipcodes = location.zipcodeIds;
    }
    @action
    handleFilterChange() {
        this.isOpen = false;
        if (this.args.onChange && this.selectedDateRange) {
            this.args.onChange({
                accountIds: this.accountFilter?.accountIds ?? [],
                accountType: this.accountFilter?.accountType ?? null,
                dateRange: {
                    datePeriod: this.selectedDateRange.id,
                    startDate: this.selectedDateRange.startDate,
                    endDate: this.selectedDateRange.endDate,
                    // We should never have an undefined value here, but we have to add a
                    // fallback to make TypeScript happy.
                    dateGrouping: this.selectedDateGrouping?.id ?? 'week'
                },
                financialResponsibilities: this.selectedFinancialResponsibilities,
                geoId: this.selectedGeo,
                rideCompletionStatuses: this.selectedRideStatuses,
                regionIds: this.selectedRegions,
                transportPartnerIds: this.selectedTransportPartners,
                transportTypeIds: this.selectedTransportTypes,
                zipcodeIds: this.selectedZipcodes
            });
        }
    }
    static{
        template_ded50772069b45ea85ea651416a58857(`
    <UiButton
      data-test-id='filters-btn'
      @icon='funnel'
      @onClick={{this.openMenu}}
    >
      Filters

      {{#if (gt this.activeFilterCount 0)}}
        <span
          class='rounded-full bg-gray-200 px-1.5 text-xs font-semibold text-gray-900'
        >
          {{this.activeFilterCount}}
        </span>
      {{/if}}
    </UiButton>

    <UiSlideover @isOpen={{this.isOpen}} @onClose={{this.closeMenu}}>
      <div class='w-80 sm:w-96 md:w-[40rem] lg:w-[48rem]'>
        <div>
          <div class='flex justify-between align-bottom'>
            <h3 class='text-lg'>Filters</h3>

            {{#if (gt this.activeFilterCount 0)}}
              <UiButton
                data-test-id='filters-reset-button'
                @size='mini'
                @onClick={{this.resetFilters}}
              >
                Reset to Default
              </UiButton>
            {{/if}}
          </div>
          <hr class='mt-1' />
        </div>

        <div class='mt-8'>
          <UiForm
            @noStyles={{true}}
            @onSubmit={{this.handleFilterChange}}
            as |Form|
          >
            <div class='grid grid-cols-1 gap-6 md:grid-cols-2'>
              <ReportingDateRangeFilter
                @form={{Form}}
                @dateRange={{this.selectedDateRange}}
                @dateGrouping={{this.selectedDateGrouping}}
                @onChange={{this.dateRangeSelected}}
              />

              <AccountFilterComponent
                @form={{Form}}
                @accountFilter={{this.accountFilter}}
                @onChange={{set this 'accountFilter'}}
              />

              <Form.Group data-test-id='transport-types' as |Group|>
                <Group.Label>Transport Types</Group.Label>
                <Group.Listbox
                  @multiple={{true}}
                  @value={{this.selectedTransportTypes}}
                  @onChange={{set this 'selectedTransportTypes'}}
                  as |listbox|
                >
                  <listbox.Button @placeholder='All Transport Types'>
                    {{this.transportTypesDisplay}}
                  </listbox.Button>

                  {{!
                    TODO: Need to figure out why the items aren't returning in the
                          correct order even though the API is returning them sorted.
                  }}
                  <listbox.Options as |options|>
                    {{#each
                      (sortBy 'fullName' this.session.viewableTransportTypes)
                      as |transportType|
                    }}
                      <options.Option @value={{transportType.id}}>
                        {{transportType.fullName}}
                      </options.Option>
                    {{/each}}
                  </listbox.Options>
                </Group.Listbox>
              </Form.Group>

              <Form.Group data-test-id='transport-partners' as |Group|>
                <Group.Label>Transport Partners</Group.Label>
                <Group.Listbox
                  @multiple={{true}}
                  @value={{this.selectedTransportPartners}}
                  @onChange={{set this 'selectedTransportPartners'}}
                  as |listbox|
                >
                  <listbox.Button @placeholder='All Transport Partners'>
                    {{this.transportPartnersDisplay}}
                  </listbox.Button>

                  {{!
                    TODO: Need to figure out why the items aren't returning in the
                          correct order even though the API is returning them sorted.
                  }}
                  <listbox.Options as |options|>
                    {{#each
                      (sortBy 'name' this.session.viewableTransportPartners)
                      as |transportPartner|
                    }}
                      <options.Option @value={{transportPartner.id}}>
                        {{transportPartner.name}}
                      </options.Option>
                    {{else}}
                      <options.Empty>
                        There are no Transport Partners.
                      </options.Empty>
                    {{/each}}
                  </listbox.Options>
                </Group.Listbox>
              </Form.Group>

              <RideStatusFilter
                @form={{Form}}
                @rideStatusIds={{this.selectedRideStatuses}}
                @onChange={{set this 'selectedRideStatuses'}}
              />

              <FinancialResponsibilityFilter
                @form={{Form}}
                @financialResponsibilities={{this.selectedFinancialResponsibilities}}
                @onChange={{set this 'selectedFinancialResponsibilities'}}
              />

              <LocationFilter
                @form={{Form}}
                @geoId={{this.selectedGeo}}
                @regionIds={{this.selectedRegions}}
                @zipcodeIds={{this.selectedZipcodes}}
                @onChange={{this.locationSelected}}
              />
            </div>

            <Form.Actions class='mt-6' as |Actions|>
              <Actions.Submit data-test-id='filters-apply-btn' @full={{true}}>
                Apply
              </Actions.Submit>
            </Form.Actions>
          </UiForm>
        </div>
      </div>
    </UiSlideover>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::Filters': typeof FiltersComponent;
    }
}
