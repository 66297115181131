import { template as template_15d0324f8a7c441bb70882a2439b7fe2 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import { restartableTask, timeout } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import { query } from 'ember-data-resources';
import UiFormGroup from '@onwardcare/ui-components/components/ui-form/group';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import type AccountTransportType from '../../../../models/account-transport-type';
import { type RideLocation } from '../location';
import type SuggestedLocationModel from '../../../../models/suggested-location';
import type SessionService from '../../../../services/onward-session';
import LocationItem from './location-item';
const PAGE_SIZE = 50;
const DEBOUNCE_MS = 400;
interface GoogleLocation {
    description: string;
    place_id: string;
    reference: string;
    structured_formatting: {
        main_text: string;
        secondary_text: string;
    };
}
interface GoogleLocationDetails {
    address_components: [{
            long_name: string;
            short_name: string;
            types: string[];
        }];
    geometry: {
        location: {
            lat: () => number;
            lng: () => number;
        };
    };
    formatted_address: string;
    name: string;
}
interface LocationComboboxSignature {
    Element: HTMLDivElement;
    Args: {
        formGroup: typeof UiFormGroup;
        location: RideLocation | null;
        onChange: (location: RideLocation) => void;
        riderId?: string;
        transportType: AccountTransportType;
        typeName: string;
    };
    Blocks: EmptyObject;
}
export default class LocationCombobox extends Component<LocationComboboxSignature> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @service
    googleMapsApi: any;
    @service('onward-session')
    session: SessionService;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @tracked
    autocompleteService: any | null = null;
    @tracked
    filter = '';
    @tracked
    locationPhoto: RideLocation | null = null;
    @tracked
    autocompleteOptions: GoogleLocation[] = [];
    get isLoading() {
        // If this is the first fetch, we want to show the loading state instead of
        // the empty state.
        const waitForFirstFetch = this.locationData.hasRan === false;
        return this.locationData.isLoading || waitForFirstFetch;
    }
    get selectedLocation() {
        const found = this.locations.find((location)=>'suggestedLocationId' in location && location.suggestedLocationId === this.args.location?.suggestedLocationId);
        return found ?? this.args.location;
    }
    get locations() {
        const suggestedLocations = this.locationData.records?.map((location)=>{
            return {
                address: location.address,
                instructions: location.startLocationInstructions,
                latitude: location.latitude,
                locationId: location.locationId,
                locationPhotoUrl: location.locationPhotoUrl,
                longitude: location.longitude,
                name: location.name,
                suggestedLocationId: location.id,
                type: location.type,
                zipcode: location.zipcode
            };
        }) ?? [];
        const autocomplete = this.autocompleteOptions.map((option)=>{
            return {
                address: option.description,
                name: option.structured_formatting.main_text,
                locationId: option.reference,
                type: 'google'
            };
        });
        return [
            ...suggestedLocations,
            ...autocomplete
        ];
    }
    get queryParams() {
        const query = this.filter !== '' ? this.filter : undefined;
        return {
            filter: {
                rider_id: this.args.riderId,
                transport_type: this.args.transportType.name,
                query
            },
            page: {
                size: PAGE_SIZE
            }
        };
    }
    locationData = query<SuggestedLocationModel>(this, 'suggested-location', ()=>({
            ...this.queryParams
        }));
    filterChanged = restartableTask(async (term: string)=>{
        await timeout(DEBOUNCE_MS);
        // Skip fetching data if the term hasn't changed.
        if (term === this.filter) {
            return;
        }
        this.filter = term;
        if (term !== '') {
            this.fetchGoogleLocations.perform();
        }
    });
    fetchGoogleLocations = restartableTask(async ()=>{
        if (!this.autocompleteService) {
            const api = await this.googleMapsApi.google;
            this.autocompleteService = new api.maps.places.AutocompleteService();
        }
        // TODO: Remove if Sohayla is fine with that or keep it and make it load
        // from the account. Will do this in another PR.
        // const centerLocation = new this.googleMapsApi.google.maps.LatLng(
        //   37.84073,
        //   -122.251691,
        // );
        // const radius = 160000;
        await this.autocompleteService.getPlacePredictions({
            input: this.filter,
            // locationBias: new this.googleMapsApi.google.maps.Circle({
            //   center: centerLocation,
            //   radius: radius,
            // }),
            components: 'country:us'
        }, (results: GoogleLocation[] | null)=>{
            this.autocompleteOptions = results ?? [];
        });
    });
    convertGoogleToLocation(option: GoogleLocationDetails) {
        let zipcode = null;
        const postalCodeComponent = option.address_components.find((c)=>{
            return c.types.includes('postal_code');
        });
        if (postalCodeComponent) {
            zipcode = postalCodeComponent.short_name;
        }
        return {
            name: option.name,
            address: option.formatted_address,
            latitude: option.geometry.location.lat(),
            longitude: option.geometry.location.lng(),
            type: 'google',
            zipcode
        } as RideLocation;
    }
    @action
    handleLocationChange(location: RideLocation) {
        if (location.type === 'google') {
            let places = new this.googleMapsApi.google.maps.places.PlacesService(document.createElement('div'));
            places.getDetails({
                reference: location.locationId
            }, (details: GoogleLocationDetails)=>{
                const updatedLocation = this.convertGoogleToLocation(details);
                this.args.onChange(updatedLocation);
            });
        } else {
            this.args.onChange(location);
        }
        this.autocompleteOptions = [];
    }
    static{
        template_15d0324f8a7c441bb70882a2439b7fe2(`
    <@formGroup as |Group|>
      <Group.Label>{{@typeName}} Location</Group.Label>
      <Group.Combobox
        class='mt-2'
        data-test-id='location-combobox'
        @value={{this.selectedLocation}}
        @onChange={{this.handleLocationChange}}
        @onFilterChange={{perform this.filterChanged}}
        as |combobox|
      >
        <combobox.Button>
          <:placeholder>
            <span class='flex items-center gap-2'>
              <UiIcon @icon='map-pin' @type='mini' />
              <span>Search for location...</span>
            </span>
          </:placeholder>

          <:default>
            {{#if @location}}
              <LocationItem @location={{@location}} />
            {{/if}}
          </:default>
        </combobox.Button>

        {{#if this.isLoading}}
          <combobox.Option @value='' @disabled={{true}}>
            Loading...
          </combobox.Option>
        {{else}}
          {{#each this.locations as |location|}}
            <combobox.Option @value={{location}} as |option|>
              <LocationItem
                @location={{location}}
                @isActive={{option.active}}
              />
            </combobox.Option>
          {{else}}
            <combobox.Empty>
              No locations found.
            </combobox.Empty>
          {{/each}}
        {{/if}}
      </Group.Combobox>
    </@formGroup>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
