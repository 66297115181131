import { template as template_886cba2f687f4960a8f8839334580c08 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { findAll } from 'ember-data-resources';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - We need to figure out how to create the types for this.
import sortBy from 'ember-composable-helpers/helpers/sort-by';
import type TransportPartnerModel from '../../models/transport-partner';
import type UiFormGroupComponent from '@onwardcare/ui-components/components/ui-form/group';
export interface TransportPartnerFilterSignature {
    Element: HTMLDivElement;
    Args: {
        // TODO: Need to figure out how to properly use the `UiForm` block types.
        form: {
            Group: typeof UiFormGroupComponent;
        };
        transportPartnerIds: string[];
        onChange: (transportPartnerIds: string[]) => unknown;
    };
    Blocks: EmptyObject;
}
export default class TransportPartnerFilterComponent extends Component<TransportPartnerFilterSignature> {
    get transportPartnersDisplay() {
        const { transportPartnerIds } = this.args;
        const transportPartners = this.transportPartnersData.records?.filter((transportPartner)=>transportPartnerIds.includes(transportPartner.id));
        return transportPartners ? transportPartners.map((transportPartner)=>transportPartner.name).join(', ') : null;
    }
    transportPartnersData = findAll<TransportPartnerModel>(this, 'transport-partner');
    static{
        template_886cba2f687f4960a8f8839334580c08(`
    <@form.Group data-test-id='transport-partners' as |Group|>
      <Group.Label>Transport Partners</Group.Label>
      <Group.Listbox
        @multiple={{true}}
        @value={{@transportPartnerIds}}
        @onChange={{@onChange}}
        as |listbox|
      >
        <listbox.Button @placeholder='All Transport Partners'>
          {{this.transportPartnersDisplay}}
        </listbox.Button>

        {{!
          TODO: Need to figure out why the items aren't returning in the
                correct order even though the API is returning them sorted.
        }}
        <listbox.Options as |options|>
          {{#each
            (sortBy 'name' this.transportPartnersData.records)
            as |transportPartner|
          }}
            <options.Option @value={{transportPartner.id}}>
              {{transportPartner.name}}
            </options.Option>
          {{else}}
            <options.Empty>
              There are no Transport Partners.
            </options.Empty>
          {{/each}}
        </listbox.Options>
      </Group.Listbox>
    </@form.Group>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Filters::TransportPartnerFilter': typeof TransportPartnerFilterComponent;
    }
}
