import { template as template_40aba5ac62dc4079ab2e1c70357ac45c } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type { EmptyObject } from '@ember/component/helper';
import UiBaseLink from '@onwardcare/ui-components/components/ui-base-link';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import InternalIcon from '../internal-icon';
import { isActiveRoute } from '../../utils/route';
import { isInternalItem, isVisibleItem, type MenuItem } from '../main-layout';
import type SessionService from '../../services/onward-session';
export interface SubMenuItemSignature {
    Element: HTMLAnchorElement;
    Args: {
        isParentInternal: boolean;
        item: MenuItem;
    };
    Blocks: EmptyObject;
}
export default class SubMenuItemComponent extends Component<SubMenuItemSignature> {
    @service
    router: RouterService;
    @service('onward-session')
    session: SessionService;
    get isVisible() {
        return isVisibleItem(this.args.item, this.session);
    }
    get showInternalIcon() {
        return this.args.isParentInternal ? false : isInternalItem(this.args.item);
    }
    get isActive() {
        const { route, model, models } = this.args.item;
        return isActiveRoute(this.router, route, model, models);
    }
    static{
        template_40aba5ac62dc4079ab2e1c70357ac45c(`
    {{#if this.isVisible}}
      <li>
        <UiBaseLink
          data-test-id={{@item.testId}}
          @href={{@item.href}}
          @route={{@item.route}}
          @model={{@item.model}}
          @models={{@item.models}}
          class='group flex items-center gap-x-2 rounded-md py-2 pl-9 pr-2 text-sm leading-6 transition focus:outline-purple-500
            {{if
              this.isActive
              "bg-purple-50 text-purple-500"
              "text-gray-700 hover:bg-purple-50 hover:text-purple-500"
            }}'
        >
          <UiIcon
            @icon='minus'
            @type='micro'
            class='shrink-0 transition
              {{if
                this.isActive
                "text-purple-500"
                "text-gray-400 group-hover:text-purple-500"
              }}'
          />

          {{@item.name}}

          {{#if this.showInternalIcon}}
            <InternalIcon @isActive={{this.isActive}} />
          {{/if}}
        </UiBaseLink>
      </li>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'MainLayout::SubMenuItem': typeof SubMenuItemComponent;
    }
}
