import { template as template_1e0555eb788542249fa51e0cf380bc53 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { ageFromDob, dateStringToDateFormat } from '@onwardcare/core/utils/date-format';
import type RiderModel from '../../../models/rider';
const RiderDetails: TOC<{
    Args: {
        highlightName?: boolean;
        isActive?: boolean;
        rider: RiderModel | null;
    };
}> = template_1e0555eb788542249fa51e0cf380bc53(`
  <span class='inline-flex flex-col truncate'>
    <span
      data-active={{@isActive}}
      class='truncate text-sm font-bold
        {{if
          @highlightName
          "text-purple-500 data-[active]:text-white"
          "text-gray-900 data-[active]:text-white"
        }}'
    >
      {{@rider.displayName}}
    </span>
    <span
      class='text-xs font-normal {{if @isActive "text-white" "text-gray-400"}}'
    >
      {{#if @rider.gender}}
        <span class='inline-flex'>
          <span data-test-id='rider-gender' class='capitalize'>
            {{@rider.gender}}
          </span>
          {{if @rider.dob ','}}
        </span>
      {{/if}}
      {{#if @rider.dob}}
        <span data-test-id='rider-dob'>
          DOB:
          {{dateStringToDateFormat @rider.dob}}
        </span>
        <span data-test-id='rider-age'>
          ({{ageFromDob @rider.dob}}yo)
        </span>
      {{/if}}
    </span>
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RiderDetails;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Rides::RideForm::RiderDetails': typeof RiderDetails;
    }
}
