import { template as template_ea7eb811fa5540f0bd6ada402f4bd8a4 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
export interface InternalIconSignature {
    Element: SVGElement;
    Args: {
        isActive?: boolean;
    };
    Blocks: EmptyObject;
}
const InternalIconComponent: TOC<InternalIconSignature> = template_ea7eb811fa5540f0bd6ada402f4bd8a4(`
  <UiIcon
    @icon='shield-check'
    @type='micro'
    class='shrink-0 transition
      {{if
        @isActive
        "text-purple-500"
        "text-purple-200 group-hover:text-purple-500"
      }}'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InternalIconComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        InternalIcon: typeof InternalIconComponent;
    }
}
