import Model, { attr, belongsTo } from '@ember-data/model';
import type ParentAccountModel from './parent-account';

export default class AccountModel extends Model {
  @attr() declare canBeInvoiced: boolean;
  @attr() declare name: string;
  @attr() declare promptForRoomNumbers: boolean;
  @attr() declare tzName: string;

  @belongsTo('parent-account', { async: false, inverse: null })
  declare parentAccount: ParentAccountModel;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    account: AccountModel;
  }
}
