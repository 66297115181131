import { template as template_5c0df3c284f442f098350d49c69bf51e } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface AverageJuiceAmountSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const AverageJuiceAmount: TOC<AverageJuiceAmountSignature> = template_5c0df3c284f442f098350d49c69bf51e(`
  <BasicColumnChart
    @format='currency'
    @modelName='average-juice-amount'
    @title='Average Juice Amount'
    @tooltipText='Average juice amount for juiced rides that were completed with a start date in the date range.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AverageJuiceAmount;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::AverageJuiceAmount': typeof AverageJuiceAmount;
    }
}
