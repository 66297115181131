import { template as template_71963ad174a84894b34d6794cb902ac1 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface LateCancellationCountsSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const LateCancellationCounts: TOC<LateCancellationCountsSignature> = template_71963ad174a84894b34d6794cb902ac1(`
  <BasicColumnChart
    @modelName='late-cancellation-count'
    @stacking='normal'
    @title='Late Ride Cancellations'
    @tooltipText="Count of ride cancellations relative to the ride's requested start time."
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LateCancellationCounts;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::LateCancellationCounts': typeof LateCancellationCounts;
    }
}
