import { template as template_9c62be06b6a3409dae8db797e9026381 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { hash } from '@ember/helper';
import type { WithBoundArgs } from '@glint/template';
const Item: TOC<{
    Element: HTMLLIElement;
    Args: {
        /**
     * Whether this is the last item in the timeline.
     */ isLast?: boolean;
    };
    Blocks: {
        default: [];
    };
}> = template_9c62be06b6a3409dae8db797e9026381(`
  <li class='relative flex gap-x-4'>
    <div
      class='absolute
        {{if @isLast "h-6" "-bottom-6"}}
        left-0 top-0 flex w-6 justify-center'
    >
      <div class='w-px bg-gray-200' />
    </div>

    <div
      class='relative flex size-6 flex-none items-center justify-center bg-white'
    >
      <div class='size-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300' />
    </div>
    <div class='grow'>
      {{yield}}
    </div>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const UiTimeline: TOC<{
    Element: HTMLUListElement;
    Blocks: {
        default: [{
                /**
         * The timeline item component.
         */ Item: WithBoundArgs<typeof Item, never>;
            }];
    };
}> = template_9c62be06b6a3409dae8db797e9026381(`
  <ul role='list' class='space-y-6' ...attributes>
    {{yield (hash Item=(component Item))}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiTimeline;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiTimeline: typeof UiTimeline;
    }
}
