import { template as template_96ac1a2e694046a68b7a4248a602dc28 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { EmptyObject } from '@ember/component/helper';
import { action } from '@ember/object';
import UiForm from '@onwardcare/ui-components/components/ui-form';
import UiSearch from '../../ui-search';
export interface SearchSignature {
    Element: HTMLFormElement;
    Args: {
        term: string;
        onChange: (term: string) => void;
    };
    Blocks: EmptyObject;
}
export default class SearchComponent extends Component<SearchSignature> {
    @action
    handleSearchClear() {
        this.args.onChange('');
    }
    @action
    handleSearch(event: SubmitEvent) {
        if (event.target instanceof HTMLFormElement) {
            const data = new FormData(event.target);
            const term = data.get('rides-search') as string;
            this.args.onChange(term);
        }
    }
    static{
        template_96ac1a2e694046a68b7a4248a602dc28(`
    <UiForm
      data-test-id='rides-search-form'
      @noStyles={{true}}
      @onSubmit={{this.handleSearch}}
      as |Form|
    >
      <Form.Group data-test-id='rides-search' as |Group|>
        <Group.Label for='rides-search' class='sr-only'>
          Search by Ride ID
        </Group.Label>
        <div class='flex w-64 items-center'>
          <UiSearch
            placeholder='Search by Ride ID'
            id='rides-search'
            name='rides-search'
            type='number'
            @term={{@term}}
            @onClear={{this.handleSearchClear}}
          />
        </div>
      </Form.Group>
    </UiForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Admin::Rides::Search': typeof SearchComponent;
    }
}
