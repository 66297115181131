import { template as template_f6307c471d8b4e15b8f1634d1f8fd3c4 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import type { WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import UiBaseLink, { type UiBaseLinkArgs } from '@onwardcare/ui-components/components/ui-base-link';
import { DEFAULT_ROUTE } from '../utils/default-routes';
interface BreadcrumbArgs extends UiBaseLinkArgs {
    isCurrent?: boolean;
}
interface BreadcrumbSignature {
    Element: HTMLLIElement;
    Args: BreadcrumbArgs;
    Blocks: {
        default: [];
    };
}
const BreadcrumbComponent: TOC<BreadcrumbSignature> = template_f6307c471d8b4e15b8f1634d1f8fd3c4(`
  <li>
    <div class='flex items-center'>
      <UiIcon
        @icon='chevron-right'
        @type='mini'
        class='flex-shrink-0 text-gray-400'
      />
      <UiBaseLink
        class='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
        @route={{@route}}
        @model={{@model}}
        @models={{@models}}
        @query={{@query}}
        aria-current={{@isCurrent}}
      >
        {{yield}}
      </UiBaseLink>
    </div>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export interface UiBreadcrumbsSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: {
        default: [{
                Breadcrumb: WithBoundArgs<typeof BreadcrumbComponent, never>;
            }];
    };
}
const UiBreadcrumbsComponent: TOC<UiBreadcrumbsSignature> = template_f6307c471d8b4e15b8f1634d1f8fd3c4(`
  <nav class='flex' aria-label='Breadcrumb'>
    <ol role='list' class='flex items-center space-x-4'>
      <li>
        <div>
          <LinkTo
            class='block h-5 w-5 text-gray-400 hover:text-gray-500'
            @route={{DEFAULT_ROUTE}}
          >
            <UiIcon @icon='home' @type='mini' class='flex-shrink-0' />
            <span class='sr-only'>Home</span>
          </LinkTo>
        </div>
      </li>

      {{yield (hash Breadcrumb=(component BreadcrumbComponent))}}
    </ol>
  </nav>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiBreadcrumbsComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiBreadcrumbs: typeof UiBreadcrumbsComponent;
    }
}
