import { template as template_0b37a7373a744079b308d555d7c1e5b5 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type { EmptyObject } from '@ember/component/helper';
import { service } from '@ember/service';
import { modifier } from 'ember-modifier';
import type Store from '@ember-data/store';
import { eq } from 'ember-truth-helpers';
import { findRecord } from 'ember-data-resources';
import type VetrideTripModel from '../../models/vetride-trip';
import type { VetrideRequestStatus } from '../../models/vetride-trip';
import UiAlert from '@onwardcare/ui-components/components/ui-alert';
import UiButton from '@onwardcare/ui-components/components/ui-button';
import UiCard from '@onwardcare/ui-components/components/ui-card';
import UiDescriptionList from '../ui-description-list';
import UiForm from '@onwardcare/ui-components/components/ui-form';
import { dateTime } from '@onwardcare/core/utils/date-format';
import { VaNotes } from './new-requests';
import type SettingsService from '../../services/settings';
import { restartableTask } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import { extractErrorMessage } from 'onward-portal/utils/error-handlers';
import CopyField from '../copy-field';
import RideIdForm from './request/ride-id-form';
import MarkClosedForm from './request/mark-closed-form';
import UnlinkRideForm from './request/unlink-ride-form';
import type UserModel from 'onward-portal/models/user';
const Divider = template_0b37a7373a744079b308d555d7c1e5b5(`<hr class='my-8' />`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface DetailsHeaderSignature {
    Element: HTMLHeadingElement;
    Args: {
        text: string;
    };
    Blocks: EmptyObject;
}
const DetailsHeader: TOC<DetailsHeaderSignature> = template_0b37a7373a744079b308d555d7c1e5b5(`
  <h3 class='font-medium leading-7 text-gray-900'>{{@text}}</h3>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export interface RequestDetailsSignature {
    Element: HTMLDivElement;
    Args: {
        rideRequest: VetrideTripModel;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        associatedRideRequest: Record<string, any> | null;
    };
    Blocks: EmptyObject;
}
export default class RequestDetailsComponent extends Component<RequestDetailsSignature> {
    @service
    settings: SettingsService;
    @service
    store: Store;
    @tracked
    iframeHeight?: number;
    // We need to pluck this off the model so that when we are saving, the fields
    // don't react until the save has successfully completed.
    @tracked
    changesDiff: Record<string, unknown> | null = null;
    @tracked
    resolveErrorMessage: string | null = null;
    @tracked
    requestStatus: VetrideRequestStatus = 'new';
    @tracked
    rideLinkStatus: 'linked' | 'unlinked' = 'unlinked';
    get iframeDomain() {
        return this.settings.getValue('DASHBOARD_URL');
    }
    get iframeUrl() {
        let rideIdPath = 'new';
        if (this.args.rideRequest.rideId) {
            rideIdPath = this.args.rideRequest.rideId.toString();
        }
        return `${this.iframeDomain}/rides/${rideIdPath}?vetrideRequestId=${this.args.rideRequest.id}`;
    }
    get hideIframe() {
        return (this.requestStatus === 'closed' && this.args.rideRequest.rideId === null);
    }
    get originDomains() {
        return [
            this.iframeDomain,
            this.settings.getValue('PORTAL_URL')
        ];
    }
    get needsResolving() {
        return this.changesDiff !== null && this.requestStatus === 'open';
    }
    get associatedTripId() {
        if (this.args.associatedRideRequest) {
            return this.args.associatedRideRequest['id'];
        }
        return null;
    }
    get associatedTripType() {
        return this.args.associatedRideRequest?.['trip_type'] ?? 'Return';
    }
    constructor(owner: unknown, args: RequestDetailsSignature['Args']){
        super(owner, args);
        const rideRequest = this.args.rideRequest;
        // We need to pluck these off the model so that when we are saving, the
        // fields don't react until the save has successfully completed.
        this.requestStatus = rideRequest.requestStatus as VetrideRequestStatus;
        this.changesDiff = rideRequest.changesDiff;
        this.rideLinkStatus = rideRequest.rideId || rideRequest.requestStatus === 'closed' ? 'linked' : 'unlinked';
    }
    closedBy = findRecord<UserModel>(this, 'user', ()=>this.args.rideRequest.closerId);
    resolveChanges = restartableTask(async ()=>{
        this.resolveErrorMessage = null;
        this.args.rideRequest.changesDiff = null;
        try {
            await this.args.rideRequest.save();
            this.changesDiff = null;
        } catch (ex) {
            this.args.rideRequest.rollbackAttributes();
            this.resolveErrorMessage = extractErrorMessage(ex, 'Something went wrong trying to resolve the updates.');
        }
    });
    initIFrame = modifier((element: HTMLIFrameElement)=>{
        window.addEventListener('message', (event)=>{
            // Only accept messages from the specified domain.
            if (this.originDomains.includes(event.origin) === false) {
                return;
            }
            // Only accept something which can be parsed as a number
            if (isNaN(event.data)) {
                return;
            }
            // Don't add any more height to this or else you will get an infinite loop.
            const height = parseInt(event.data);
            if (height !== this.iframeHeight) {
                this.iframeHeight = height;
                element.style.height = height + 'px';
            }
        }, false);
    });
    changes = modifier((element: HTMLElement, [fieldName]: [string])=>{
        if (this.args.rideRequest.requestStatus !== 'open') {
            return;
        }
        if (this.changesDiff?.[fieldName]) {
            element.classList.add('bg-yellow-100');
            element.dataset['hasChanges'] = 'true';
        } else {
            element.classList.remove('bg-yellow-100');
            delete element.dataset['hasChanges'];
        }
    });
    nullable(value: string | null) {
        return value ? value : 'N/A';
    }
    dateTimeTz(date: Date | null | undefined, timeZone?: string) {
        return dateTime(date, {
            timeZone,
            nullText: 'N/A'
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    asString(value: any | null) {
        return value?.toString();
    }
    @action
    markedAsClosed() {
        this.requestStatus = 'closed';
    }
    @action
    rideLinked() {
        this.rideLinkStatus = 'linked';
    }
    @action
    rideUnlinked() {
        this.rideLinkStatus = 'unlinked';
    }
    static{
        template_0b37a7373a744079b308d555d7c1e5b5(`
    <div class='flex flex-col gap-2'>
      <div class='grid min-h-[40px] grid-cols-2 items-center gap-8'>
        <div class='flex items-center justify-between gap-2'>
          <h2 class='text-lg font-semibold'>VetRide Request</h2>
          {{#if @associatedRideRequest}}
            <UiButton
              data-test-id='associated-trip-btn'
              @size='small'
              @route='authenticated.va-tools.ride-requests.details'
              @model={{this.associatedTripId}}
            >
              {{this.associatedTripType}}
              Request
            </UiButton>
          {{/if}}
        </div>
        <div class='flex items-center justify-between gap-2'>
          <h2 class='text-lg font-semibold'>Onward Ride</h2>
        </div>
      </div>

      <div class='grid grid-cols-2 items-start gap-8'>
        <UiCard>
          {{#if (eq this.requestStatus 'closed')}}
            <UiDescriptionList as |List|>
              <List.Item data-test-id='closed-date'>
                <:key>Closed Date</:key>
                <:value>{{this.dateTimeTz
                    @rideRequest.closedAt
                    @rideRequest.accountTz
                  }}</:value>
              </List.Item>
              <List.Item data-test-id='closed-by'>
                <:key>Closed By</:key>
                <:value>{{this.closedBy.record.firstName}}
                  {{this.closedBy.record.lastName}}</:value>
              </List.Item>
            </UiDescriptionList>
          {{else if this.needsResolving}}
            <UiAlert
              data-test-id='has-updates-message'
              @variant='warning'
              class='mb-6'
            >
              <p>
                There have been changes to the VetRides request since an Onward
                ride was booked. The fields that have changed are highlighted
                below.
              </p>
              <p class='mt-3'>
                Review the changes and ensure that those changes have been
                reflected in the associated ride and rider information in
                Onward.
              </p>

              {{#if this.resolveErrorMessage}}
                <div
                  data-test-id='resolve-error-message'
                  class='mt-3 text-red-700'
                >
                  {{this.resolveErrorMessage}}
                </div>
              {{/if}}

              <UiForm
                class='mt-3'
                @onSubmit={{perform this.resolveChanges}}
                as |Form|
              >
                <Form.Actions as |Actions|>
                  <Actions.Submit data-test-id='resolve-btn' @size='small'>
                    Resolve Updates
                  </Actions.Submit>
                </Form.Actions>
              </UiForm>
            </UiAlert>
          {{else}}
            <MarkClosedForm
              @rideRequest={{@rideRequest}}
              @onSaved={{this.markedAsClosed}}
            />
          {{/if}}

          <Divider />

          <UiDescriptionList as |List|>
            <List.Item>
              <:key>Request Import Date</:key>
              <:value>{{this.dateTimeTz
                  @rideRequest.createdAt
                  @rideRequest.accountTz
                }}</:value>
            </List.Item>
            <List.Item>
              <:key>Request Account</:key>
              <:value>{{@rideRequest.accountName}}</:value>
            </List.Item>
          </UiDescriptionList>

          <Divider />

          <DetailsHeader @text='Passenger Info' />
          <UiDescriptionList class='mt-6' as |List|>
            <List.Item data-test-id='passenger' {{this.changes 'passenger'}}>
              <:key>Name</:key>
              <:value>
                <CopyField @text={{@rideRequest.passenger}} />
              </:value>
            </List.Item>
            <List.Item data-test-id='phone' {{this.changes 'phoneNumber'}}>
              <:key>Phone</:key>
              <:value>
                <CopyField @text={{@rideRequest.phoneNumber}}>
                  {{this.nullable @rideRequest.phoneNumber}}
                </CopyField>
              </:value>
            </List.Item>
          </UiDescriptionList>

          <Divider />

          <DetailsHeader @text='Trip Timing' />
          <UiDescriptionList class='mt-6' as |List|>
            <List.Item
              data-test-id='pickup-time'
              {{this.changes 'estimatedPickupTime'}}
            >
              <:key>Estimated Pickup</:key>
              <:value>
                {{this.dateTimeTz
                  @rideRequest.estimatedPickupTime
                  @rideRequest.accountTz
                }}
              </:value>
            </List.Item>
            <List.Item
              data-test-id='departure-time'
              {{this.changes 'earliestDepartureTime'}}
            >
              <:key>Earliest Departure</:key>
              <:value>
                {{this.dateTimeTz
                  @rideRequest.earliestDepartureTime
                  @rideRequest.accountTz
                }}
              </:value>
            </List.Item>
            <List.Item
              data-test-id='latest-time'
              {{this.changes 'latestArrivalTime'}}
            >
              <:key>Latest Arrival</:key>
              <:value>
                {{this.dateTimeTz
                  @rideRequest.latestArrivalTime
                  @rideRequest.accountTz
                }}
              </:value>
            </List.Item>
            <List.Item
              data-test-id='appointment-time'
              {{this.changes 'appointmentTime'}}
            >
              <:key>Appointment Time</:key>
              <:value>
                {{this.dateTimeTz
                  @rideRequest.appointmentTime
                  @rideRequest.accountTz
                }}
              </:value>
            </List.Item>
            <List.Item data-test-id='buffer' {{this.changes 'bufferMinutes'}}>
              <:key>Buffer Minutes</:key>
              <:value>{{@rideRequest.bufferMinutes}}</:value>
            </List.Item>
          </UiDescriptionList>

          <Divider />

          <DetailsHeader @text='Trip Details' />
          <UiDescriptionList class='mt-6' as |List|>
            <List.Item>
              <:key>Trip ID</:key>
              <:value>
                <CopyField @text={{@rideRequest.tripId}} />
              </:value>
            </List.Item>
            <List.Item data-test-id='trip-type' {{this.changes 'tripType'}}>
              <:key>Trip Type</:key>
              <:value>{{@rideRequest.tripType}}</:value>
            </List.Item>
            <List.Item data-test-id='status' {{this.changes 'status'}}>
              <:key>Status</:key>
              <:value>{{@rideRequest.status}}</:value>
            </List.Item>
            <List.Item
              data-test-id='pickup-address'
              {{this.changes 'pickupAddress'}}
            >
              <:key>Pickup Address</:key>
              <:value>
                <CopyField @text={{@rideRequest.pickupAddress}} />
              </:value>
            </List.Item>
            <List.Item
              data-test-id='dropoff-address'
              {{this.changes 'dropoffAddress'}}
            >
              <:key>Dropoff Address</:key>
              <:value>
                <CopyField @text={{@rideRequest.dropoffAddress}} />
              </:value>
            </List.Item>
            <List.Item data-test-id='mobility' {{this.changes 'mobility'}}>
              <:key>Mobility</:key>
              <:value>{{@rideRequest.mobility}}</:value>
            </List.Item>
          </UiDescriptionList>

          <Divider />

          <DetailsHeader @text='Additional' />
          <UiDescriptionList class='mt-6' as |List|>
            <List.Item data-test-id='va-notes' {{this.changes 'vaNotes'}}>
              <:key>Notes</:key>
              <:value>
                <CopyField @text={{@rideRequest.vaNotes}}>
                  <VaNotes @notes={{this.nullable @rideRequest.vaNotes}} />
                </CopyField>
              </:value>
            </List.Item>
            <List.Item data-test-id='comments' {{this.changes 'comments'}}>
              <:key>Comments</:key>
              <:value>
                <CopyField @text={{@rideRequest.comments}}>
                  {{this.nullable @rideRequest.comments}}
                </CopyField>
              </:value>
            </List.Item>
          </UiDescriptionList>

          <Divider />

          {{#if (eq this.rideLinkStatus 'unlinked')}}
            <RideIdForm
              @rideRequest={{@rideRequest}}
              @onSaved={{this.rideLinked}}
            />
          {{else}}
            <UiDescriptionList class='mt-6' as |List|>
              <List.Item data-test-id='ride-id'>
                <:key>Onward Ride ID</:key>
                <:value>
                  {{#if @rideRequest.rideId}}
                    <CopyField @text={{this.asString @rideRequest.rideId}} />
                  {{else}}
                    N/A
                  {{/if}}

                  {{#unless (eq this.requestStatus 'closed')}}
                    <UnlinkRideForm
                      @rideRequest={{@rideRequest}}
                      @onSaved={{this.rideUnlinked}}
                    />
                  {{/unless}}
                </:value>
              </List.Item>
            </UiDescriptionList>
          {{/if}}
        </UiCard>

        <div class='h-full'>
          {{#unless this.hideIframe}}
            <iframe
              data-test-id='dashboard-iframe'
              title='Book Transportation'
              src={{this.iframeUrl}}
              class='min-h-full w-full'
              {{this.initIFrame}}
            />
          {{/unless}}
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'VaTools::RequestDetails': typeof RequestDetailsComponent;
    }
}
