import { template as template_5afcd708141d41f28f2eff333d205b58 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
/**
 * Returns the color class for the specified location type.
 *
 * @param item The type of the location.
 * @returns The color class for the location type.
 */ export function colorForType(type?: string) {
    switch(type){
        case 'home':
            return 'text-purple-500';
        case 'predefined':
            return 'text-lime-600';
        case 'recent':
            return 'text-purple-400';
        default:
            return 'text-gray-900';
    }
}
export default class LocationItem extends Component<{
    Args: {
        isActive?: boolean;
        location: {
            address: string;
            name: string;
            type?: string;
        };
    };
}> {
    get icon() {
        switch(this.args.location.type){
            case 'home':
                return 'home';
            case 'predefined':
                return 'building-office-2';
            case 'recent':
                return 'arrow-uturn-left';
            default:
                return 'map-pin';
        }
    }
    get color() {
        return colorForType(this.args.location.type);
    }
    static{
        template_5afcd708141d41f28f2eff333d205b58(`
    <span class='flex items-center gap-2'>
      <UiIcon
        @icon={{this.icon}}
        @type='mini'
        class='shrink-0 {{if @isActive "text-white" this.color}}'
      />

      <span class='inline-flex flex-col truncate'>
        <span
          data-active={{@isActive}}
          class='truncate text-sm font-bold
            {{if @isActive "text-white" this.color}}'
        >
          {{@location.name}}
        </span>
        <span
          class='truncate text-xs font-normal
            {{if @isActive "text-white" "text-gray-900"}}'
        >
          {{@location.address}}
        </span>
      </span>
    </span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
