import Service from '@ember/service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { all, restartableTask } from 'ember-concurrency';
import type TransportReason from '../models/transport-reason';

/**
 * The account service provides access to account based settings.
 */
export default class AccountService extends Service {
  @service declare store: Store;

  @tracked _transportReasons: TransportReason[] = [];

  /**
   * Returns the transport reasons that are available to the current user for
   * their account.
   */
  get transportReasons() {
    return this._transportReasons;
  }

  /**
   * Loads all the account data needed when booking rides.
   */
  loadRideSettings = restartableTask(async () => {
    const requests = [this._fetchTransportReasons.perform()];

    await all(requests);
  });

  _fetchTransportReasons = restartableTask(async () => {
    // If we already have the transport reasons, don't fetch them again.
    if (this._transportReasons.length > 0) {
      return;
    }

    const data = await this.store.findAll('transport-reason');
    this._transportReasons = data.slice();
  });
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:account')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('account') declare altName: AccountService;`.
declare module '@ember/service' {
  interface Registry {
    account: AccountService;
  }
}
