import { template as template_cf189b5ebd4042f9b94547e8fa01de21 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { or } from 'ember-truth-helpers';
import type { EmptyObject } from '@ember/component/helper';
import InternalIcon from '../internal-icon';
import type RouterService from '@ember/routing/router-service';
import type SessionService from '../../services/onward-session';
import UiAvatar from '../ui-avatar';
import UiMenu from '../ui-menu';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import { getInitials } from '../../utils/initials';
export interface UserMenuSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
export default class UserMenuComponent extends Component<UserMenuSignature> {
    @service
    router: RouterService;
    @service('onward-session')
    session: SessionService;
    @action
    signOut() {
        return this.router.transitionTo('sign-out');
    }
    static{
        template_cf189b5ebd4042f9b94547e8fa01de21(`
    <UiMenu as |menu|>
      <menu.Trigger
        class='-m-1.5 flex items-center p-1.5 focus:outline-purple-500'
        data-test-id='user-menu'
      >
        <UiAvatar @size='medium'>
          <:fallback>
            {{getInitials
              this.session.user.firstName
              this.session.user.lastName
            }}
          </:fallback>
        </UiAvatar>

        <span class='hidden lg:flex lg:items-center'>
          <span
            class='ml-4 text-sm font-semibold leading-6 text-gray-900'
            aria-hidden='true'
            data-test-id='user-name'
          >
            {{this.session.user.displayName}}
          </span>
          <UiIcon
            @icon='chevron-down'
            @type='mini'
            class='ml-2 text-gray-400'
          />
        </span>
      </menu.Trigger>

      {{#if (or this.session.isAdmin this.session.isImpersonating)}}
        {{#if this.session.isImpersonating}}
          <menu.Button
            @route='authenticated.stop-impersonation'
            data-test-id='stop-impersonation-btn'
          >
            <span class='flex items-center gap-2'>
              Stop Impersonation
              <InternalIcon />
            </span>
          </menu.Button>
        {{else}}
          <menu.Button
            @route='authenticated.impersonate'
            data-test-id='impersonate-btn'
          >
            <span class='flex items-center gap-2'>
              Impersonate
              <InternalIcon />
            </span>
          </menu.Button>
        {{/if}}

        <menu.Divider />
      {{/if}}

      <menu.Button @onClick={{this.signOut}} data-test-id='sign-out-btn'>
        Sign out
      </menu.Button>
    </UiMenu>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'MainLayout::UserMenu': typeof UserMenuComponent;
    }
}
