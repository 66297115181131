import { template as template_46fa27a223584711957cc0ceb9e18680 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AdapterError from '@ember-data/adapter/error';
import type { EmptyObject } from '@ember/component/helper';
import { parseHttpError } from 'onward-portal/utils/error-handlers';
interface PageErrorSignature {
    Element: HTMLDivElement;
    Args: {
        adapterError: AdapterError;
    };
    Blocks: EmptyObject;
}
export default class PageErrorComponent extends Component<PageErrorSignature> {
    @tracked
    showTooltip = false;
    get error() {
        const payload = parseHttpError(this.args.adapterError);
        const { errors } = payload ?? {};
        // This is for JSON:API endpoints.
        if (errors && errors.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return errors[0] as Record<string, any>;
        }
        // This is for REST endpoints.
        return payload;
    }
    get errorStatus() {
        const { status } = this.error ?? {};
        return status;
    }
    get errorMessage() {
        const { detail, message } = this.error ?? {};
        return detail ?? message;
    }
    static{
        template_46fa27a223584711957cc0ceb9e18680(`
    <div data-test-id='page-error' class='text-center'>
      <p data-test-id='status' class='text-base font-semibold text-purple-500'>
        {{this.errorStatus}}
      </p>

      <h1
        data-test-id='page-title'
        class='mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl'
      >
        An Error Occurred
      </h1>

      <p data-test-id='message' class='mt-6 text-base leading-7 text-gray-600'>
        {{this.errorMessage}}
      </p>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        PageError: typeof PageErrorComponent;
    }
}
