import { template as template_f39b1c61f265444f8d62e53a8a2f7b91 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import TooltipButton from './tooltip-button';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
const InfoButton: TOC<{
    Element: HTMLButtonElement;
    Args: {
        popover?: boolean;
    };
    Blocks: {
        default: [];
    };
}> = template_f39b1c61f265444f8d62e53a8a2f7b91(`
  <TooltipButton
    data-test-id='info-button'
    class='px-1 text-base leading-normal'
    @popover={{@popover}}
    ...attributes
  >
    <:button>
      <UiIcon @icon='information-circle' @type='micro' class='block' />
      <span class='sr-only'>Info</span>
    </:button>
    <:tooltip>
      {{yield}}
    </:tooltip>
  </TooltipButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InfoButton;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        InfoButton: typeof InfoButton;
    }
}
