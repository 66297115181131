import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type SessionService from '../../../services/onward-session';
import type Transition from '@ember/routing/transition';
import { isPresent } from '@ember/utils';
import { arrayToString } from '../../../utils/serialize';
import type { AccountType } from '../../../components/filters/account-filter';
import { dateRanges as RequestedStartTimeDateRanges } from '../../../components/filters/requested-start-time-filter';
import { dateRanges as BookTimeDateRanges } from '../../../components/filters/book-time-filter';
import {
  buildDateRangeFilter,
  type FilterDateRange,
} from '../../../utils/filters/date-range';
import { captureMessage } from '../../../utils/error-logging';

export default class RidesRoute extends Route {
  @service declare router: RouterService;
  @service('onward-session') declare session: SessionService;

  async beforeModel(transition: Transition) {
    if (this.session.isOnwardAssociate === false) {
      captureMessage('Access denied', {
        from: transition.from?.name,
        to: transition.to?.name,
      });

      return this.router.replaceWith('access-denied');
    }
  }
}

export type RidesQueryParams = {
  accountIds: string[];
  accountType: string | null;
  bookTimePeriod: string | null;
  bookTimeStart: string | null;
  bookTimeEnd: string | null;
  custodianId: string | null;
  distanceIds: string[];
  driverId: string | null;
  financialResponsibilities: string[];
  geoId: string | null;
  page: number;
  regionIds: string[];
  requestedStartTimePeriod: string | null;
  requestedStartTimeStart: string | null;
  requestedStartTimeEnd: string | null;
  rideCompletionStatuses: string[];
  riderId: string | null;
  rideTypeIds: string[];
  search: string;
  sort: string | null;
  transportPartnerIds: string[];
  transportTypeIds: string[];
  zipcodeIds: string[];
};

export const queryParams = {
  accountIds: { refreshModel: true },
  accountType: { refreshModel: true },
  bookTimePeriod: { refreshModel: true },
  bookTimeStart: { refreshModel: true },
  bookTimeEnd: { refreshModel: true },
  custodianId: { refreshModel: true },
  distanceIds: { refreshModel: true },
  driverId: { refreshModel: true },
  financialResponsibilities: { refreshModel: true },
  geoId: { refreshModel: true },
  page: { refreshModel: true },
  regionIds: { refreshModel: true },
  requestedStartTimePeriod: { refreshModel: true },
  requestedStartTimeStart: { refreshModel: true },
  requestedStartTimeEnd: { refreshModel: true },
  rideCompletionStatuses: { refreshModel: true },
  riderId: { refreshModel: true },
  rideTypeIds: { refreshModel: true },
  search: { refreshModel: true },
  sort: { refreshModel: true },
  transportPartnerIds: { refreshModel: true },
  transportTypeIds: { refreshModel: true },
  zipcodeIds: { refreshModel: true },
};

export interface RidesFilters {
  id?: string;
  account_id?: string;
  channel?: string;
  created_at?: string | object;
  custodian_id?: string;
  distance_range?: string;
  driver_id?: string;
  geo_id?: string;
  payer_type?: string;
  parent_account_id?: string;
  region_id?: string;
  requested_start_time?: string | object;
  ride_completion_status?: string;
  rider_id?: string;
  ride_type?: string;
  transport_partner_id?: string;
  transport_type_id?: string;
  zipcode_id?: string;
}

export function buildFilterFromQueryParams(params: RidesQueryParams) {
  let filter: RidesFilters = {};
  const {
    accountIds,
    accountType,
    bookTimePeriod,
    bookTimeStart,
    bookTimeEnd,
    custodianId,
    distanceIds,
    driverId,
    financialResponsibilities,
    geoId,
    regionIds,
    requestedStartTimePeriod,
    requestedStartTimeStart,
    requestedStartTimeEnd,
    rideCompletionStatuses,
    riderId,
    rideTypeIds,
    search,
    transportPartnerIds,
    transportTypeIds,
    zipcodeIds,
  } = params;

  if (accountType) {
    const convertedType = accountType as AccountType;

    switch (convertedType) {
      case 'b2b':
      case 'b2c':
        filter = {
          ...filter,
          channel: convertedType === 'b2c' ? 'b2c,b2b2c' : 'b2b',
        };
        break;
      case 'parent':
        filter = { ...filter, parent_account_id: arrayToString(accountIds) };
        break;
      case 'selected':
        filter = { ...filter, account_id: arrayToString(accountIds) };
        break;
    }
  }

  if (isPresent(bookTimePeriod)) {
    filter = {
      ...filter,
      created_at: buildDateRangeFilter(
        {
          datePeriod: bookTimePeriod,
          startDate: bookTimeStart,
          endDate: bookTimeEnd,
        } as FilterDateRange,
        BookTimeDateRanges,
      ),
    };
  }

  if (isPresent(financialResponsibilities)) {
    filter = {
      ...filter,
      payer_type: arrayToString(financialResponsibilities),
    };
  }

  if (geoId) {
    filter = { ...filter, geo_id: geoId };
  }

  if (isPresent(regionIds)) {
    filter = { ...filter, region_id: arrayToString(regionIds) };
  }

  if (isPresent(requestedStartTimePeriod)) {
    filter = {
      ...filter,
      requested_start_time: buildDateRangeFilter(
        {
          datePeriod: requestedStartTimePeriod,
          startDate: requestedStartTimeStart,
          endDate: requestedStartTimeEnd,
        } as FilterDateRange,
        RequestedStartTimeDateRanges,
      ),
    };
  }

  if (isPresent(rideCompletionStatuses)) {
    filter = {
      ...filter,
      ride_completion_status: arrayToString(rideCompletionStatuses),
    };
  }

  if (isPresent(rideTypeIds)) {
    filter = {
      ...filter,
      ride_type: arrayToString(rideTypeIds),
    };
  }

  if (isPresent(transportPartnerIds)) {
    filter = {
      ...filter,
      transport_partner_id: arrayToString(transportPartnerIds),
    };
  }

  if (isPresent(transportTypeIds)) {
    filter = {
      ...filter,
      transport_type_id: arrayToString(transportTypeIds),
    };
  }

  if (isPresent(zipcodeIds)) {
    filter = { ...filter, zipcode_id: arrayToString(zipcodeIds) };
  }

  if (isPresent(distanceIds)) {
    filter = {
      ...filter,
      distance_range: arrayToString(distanceIds),
    };
  }

  if (riderId) {
    filter = { ...filter, rider_id: riderId };
  }

  if (driverId) {
    filter = { ...filter, driver_id: driverId };
  }

  if (custodianId) {
    filter = { ...filter, custodian_id: custodianId };
  }

  if (isPresent(search)) {
    filter = { ...filter, id: search };
  }

  return filter;
}
