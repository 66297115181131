import { template as template_033230234a8142b9ba53942fded216fe } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
// @ts-expect-error - Need to create the types for this.
import EmberPopover from 'ember-tooltips/components/ember-popover';
import set from 'ember-set-helper/helpers/set';
import { eq } from 'ember-truth-helpers';
import { on } from '@ember/modifier';
import { isBefore } from 'date-fns';
import { dateOnly, dateStringToISO, timeOnly, timeZone } from '@onwardcare/core/utils/date-format';
import UiButton from '@onwardcare/ui-components/components/ui-button';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import UiInputGroup from '@onwardcare/ui-components/components/ui-input-group';
import { updateDateFromDatePicker, updateDateFromTimePicker } from '../utils/date-format';
type TimezoneItem = {
    id: string;
    name: string;
};
const TIMEZONES: TimezoneItem[] = [
    {
        id: 'America/Los_Angeles',
        name: 'Pacific Time'
    },
    {
        id: 'America/Denver',
        name: 'Mountain Time'
    },
    {
        id: 'America/Phoenix',
        name: 'Mountain Time (Arizona)'
    },
    {
        id: 'America/Chicago',
        name: 'Central Time'
    },
    {
        id: 'America/New_York',
        name: 'Eastern Time'
    }
];
interface UiDateTimePickerSignature {
    Element: HTMLDivElement;
    Args: {
        minDate?: string;
        onChange: (value: string) => void;
        timezone: string;
        showCalculator?: boolean;
        value: string;
    };
    Blocks: {
        default: [];
    };
}
export default class UiDateTimePicker extends Component<UiDateTimePickerSignature> {
    @tracked
    showTimezonePopover = false;
    get timeZoneAbbr() {
        return timeZone(new Date(this.args.value), {
            timeZone: this.args.timezone
        });
    }
    get dateMin() {
        return this.args.minDate ? dateStringToISO(this.args.minDate) : undefined;
    }
    get timeMin() {
        return this.args.minDate ? timeOnly(this.args.minDate, {
            hideTimeZone: true,
            timeFormat: '24'
        }) : undefined;
    }
    get date() {
        const date = dateOnly(this.args.value, {
            timeZone: this.args.timezone
        });
        return dateStringToISO(date);
    }
    get time() {
        return timeOnly(this.args.value, {
            hideTimeZone: true,
            timeFormat: '24',
            timeZone: this.args.timezone
        });
    }
    @action
    handleDateChange(event: FocusEvent) {
        const value = (event.target as HTMLInputElement).value;
        if (value === '') {
            return;
        }
        let newDate = updateDateFromDatePicker(this.args.value, value);
        // We need to make sure that nothing is ever older than this minimum date.
        // Otherwise, you get some really weird timezones. We could have this use
        // the `minDate` argument if it's set, but if the minimum date is today, it
        // might be better to see validation errors than to just have the date be
        // changed automatically.
        const minDate = '2000-01-01';
        if (isBefore(newDate, new Date(minDate))) {
            newDate = updateDateFromDatePicker(this.args.value, minDate);
        }
        this.args.onChange(newDate);
    }
    @action
    handleTimeChange(value: string) {
        if (value === '') {
            return;
        }
        const newDate = updateDateFromTimePicker(this.args.value, value);
        this.args.onChange(newDate);
    }
    static{
        template_033230234a8142b9ba53942fded216fe(`
    <div class='mt-2 flex flex-col gap-2 sm:flex-row' ...attributes>
      <UiInputGroup class='sm:max-w-[172px]'>
        <:leadingIcon as |Icon|>
          <Icon @icon='calendar' />
        </:leadingIcon>
        <:input as |I|>
          <I
            type='date'
            data-test-id='date-input'
            min={{this.dateMin}}
            @value={{this.date}}
            {{!
              Using "blur" instead of "change" to prevent invalid dates from
              being sent and resulting in errors.
            }}
            {{on 'blur' this.handleDateChange}}
          />
        </:input>
      </UiInputGroup>

      <div class='flex flex-1 flex-row gap-2'>
        <UiInputGroup>
          <:leadingIcon as |Icon|>
            <Icon @icon='clock' />
          </:leadingIcon>
          <:input as |I|>
            <I
              type='time'
              data-test-id='time-input'
              min={{this.timeMin}}
              @value={{this.time}}
              @onChange={{this.handleTimeChange}}
            />
          </:input>
          <:trailingButton as |Button|>
            <Button
              data-test-id='timezone-button'
              @onClick={{set this 'showTimezonePopover' true}}
            >
              <span class='flex gap-1 text-gray-400'>
                <UiIcon @icon='globe-alt' @type='mini' />
                <span>{{this.timeZoneAbbr}}</span>
              </span>

              <TimezonePopover
                @currentTimezone={{@timezone}}
                @dateTime={{@value}}
                @show={{this.showTimezonePopover}}
              />
            </Button>
          </:trailingButton>
        </UiInputGroup>

        {{#if @showCalculator}}
          <UiButton
            data-test-id='calculator-button'
            @size='small'
            class='flex-none'
          >
            <span class='-mx-1'>
              <UiIcon
                @icon='calculator'
                @type='outline'
                class='text-purple-500'
              />
              <span class='sr-only'>Arrive by time calculator</span>
            </span>
          </UiButton>
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
class TimezonePopover extends Component<{
    Args: {
        currentTimezone: string;
        dateTime: string;
        show: boolean;
    };
}> {
    get popperOptions() {
        return {
            // This option prevents the tooltip from rendering off screen.
            modifiers: {
                preventOverflow: {
                    escapeWithReference: false
                }
            }
        };
    }
    static{
        template_033230234a8142b9ba53942fded216fe(`
    <EmberPopover
      @arrowClass='hidden'
      @tooltipClass='font-normal text-left bg-white shadow-md p-4'
      @spacing={{4}}
      @side='bottom-end'
      @isShown={{@show}}
      @delayOnChange={{false}}
      @effect='fade'
      @event='click'
      @popperOptions={{this.popperOptions}}
    >
      <div class='flex flex-col gap-3'>
        {{#each TIMEZONES as |tz|}}
          <TimezoneOption
            @dateTime={{@dateTime}}
            @isActive={{eq @currentTimezone tz.id}}
            @timezone={{tz}}
          />
        {{/each}}
      </div>
    </EmberPopover>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
class TimezoneOption extends Component<{
    Args: {
        dateTime: string;
        isActive: boolean;
        timezone: TimezoneItem;
    };
}> {
    get timeDisplay() {
        return timeOnly(this.args.dateTime, {
            timeZone: this.args.timezone.id
        });
    }
    static{
        template_033230234a8142b9ba53942fded216fe(`
    <span class='flex gap-1 text-gray-900 {{if @isActive "font-semibold"}}'>
      {{this.timeDisplay}}
      -
      {{@timezone.name}}
    </span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
