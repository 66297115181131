import { template as template_914399fb0aed41b1b2ff571767c91448 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import type { WithBoundArgs } from '@glint/template';
import { action } from '@ember/object';
import { hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { eq } from 'ember-truth-helpers';
import { modifier } from 'ember-modifier';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
import UiPagination from './ui-pagination';
import type { PageMeta } from './ui-pagination';
export type SortDirection = 'asc' | 'desc';
export interface ColumnOutput {
    sortColumn: string | null;
    sortDirection: SortDirection | null;
}
const DEFAULT_COLUMN_OUTPUT: ColumnOutput = {
    sortColumn: null,
    sortDirection: null
};
/**
 * Converts a `ColumnOutput` object to a string that can be used in the sort
 * query parameter.
 *
 * @param {ColumnOutput} output The `ColumnOutput` object to convert from.
 * @returns {String} Returns a string that can be used in the sort query parameter.
 */ export function convertToSort(output: ColumnOutput) {
    let sort = output.sortColumn;
    if (output.sortDirection === 'desc') {
        sort = `-${sort}`;
    }
    return sort;
}
/**
 * Converts a sort query parameter string into a `ColumnOutput` object.
 *
 * @param {string} sort The sort query parameter string to convert from.
 * @returns {ColumnOutput} Returns a `ColumnOutput` object.
 */ export function convertToColumnOutput(sort: string | null) {
    if (sort) {
        const sortDirection = sort.startsWith('-') ? 'desc' : 'asc';
        let sortColumn = sort;
        if (sort.startsWith('-')) {
            sortColumn = sort.slice(1);
        }
        return {
            sortColumn,
            sortDirection
        } as ColumnOutput;
    }
    return DEFAULT_COLUMN_OUTPUT;
}
interface UiTableCellSignature {
    Element: HTMLTableCellElement;
    Args: {
        accent?: boolean;
        align?: 'left' | 'right';
        allowWrap?: boolean;
    };
    Blocks: {
        default: [];
    };
}
class UiTableCell extends Component<UiTableCellSignature> {
    get align() {
        return this.args.align ?? 'left';
    }
    static{
        template_914399fb0aed41b1b2ff571767c91448(`
    <td
      class='px-3 py-4 text-sm text-gray-700 first:pl-4 first:pr-3 last:pl-3 last:pr-4 sm:first:pl-6 sm:last:pr-6
        {{if @accent "font-medium"}}
        {{if (eq this.align "right") "text-right"}}
        {{if @allowWrap "whitespace-normal" "whitespace-nowrap"}}
        '
      ...attributes
    >
      {{yield}}
    </td>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
interface UiTableEmptySignature {
    Element: HTMLTableRowElement;
    Args: {
        isLoading?: boolean;
    };
    Blocks: {
        default: [];
    };
}
const UiTableEmpty: TOC<UiTableEmptySignature> = template_914399fb0aed41b1b2ff571767c91448(`
  {{#unless @isLoading}}
    <tr data-test-id='no-records' class='border-b' ...attributes>
      <td class='p-3 py-6 text-center italic text-gray-500' colspan='100%'>
        {{yield}}
      </td>
    </tr>
  {{/unless}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface UiTableErrorSignature {
    Element: HTMLTableRowElement;
    Args: EmptyObject;
    Blocks: {
        default: [];
    };
}
const UiTableError: TOC<UiTableErrorSignature> = template_914399fb0aed41b1b2ff571767c91448(`
  <tr data-test-id='error-message' class='border-b bg-red-50' ...attributes>
    <td class='p-3 py-6 text-center italic text-red-800' colspan='100%'>
      {{yield}}
    </td>
  </tr>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface UiTableRowSignature {
    Element: HTMLTableRowElement;
    Args: EmptyObject;
    Blocks: {
        default: [{
                Td: WithBoundArgs<typeof UiTableCell, never>;
            }];
    };
}
const UiTableRow: TOC<UiTableRowSignature> = template_914399fb0aed41b1b2ff571767c91448(`
  <tr ...attributes>
    {{yield (hash Td=(component UiTableCell))}}
  </tr>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface UiTableBodySignature {
    Element: HTMLElement;
    Args: {
        isLoading?: boolean;
    };
    Blocks: {
        default: [{
                Empty: WithBoundArgs<typeof UiTableEmpty, never>;
                Error: WithBoundArgs<typeof UiTableError, never>;
                Tr: WithBoundArgs<typeof UiTableRow, never>;
            }];
    };
}
const UiTableBody: TOC<UiTableBodySignature> = template_914399fb0aed41b1b2ff571767c91448(`
  <tbody class='divide-y divide-gray-200 bg-white'>
    {{#if @isLoading}}
      <div
        class='absolute inset-0 z-10 flex items-center justify-center bg-gray-400/60'
      >
        <UiIcon
          data-test-id='loading-spinner'
          @icon='spinner'
          @spin={{true}}
          class='h-12 w-12 text-gray-700'
        />
      </div>
    {{/if}}

    {{yield
      (hash
        Empty=(component UiTableEmpty isLoading=@isLoading)
        Error=UiTableError
        Tr=UiTableRow
      )
    }}
  </tbody>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface UiTableHeadCellSignature {
    Element: HTMLTableCellElement;
    Args: {
        align?: 'left' | 'right';
        currentSort?: ColumnOutput;
        name?: string;
        onClick?: (output: ColumnOutput) => unknown;
        reverse?: boolean;
    };
    Blocks: {
        default: [];
    };
}
class UiTableHeadCell extends Component<UiTableHeadCellSignature> {
    @tracked
    sortDirection: ColumnOutput['sortDirection'] = null;
    get isSortable() {
        return this.args.name !== undefined;
    }
    get sortColumn() {
        return this.sortDirection && this.args.name ? this.args.name : null;
    }
    get isAscending() {
        return this.sortDirection === 'asc';
    }
    get isDescending() {
        return this.sortDirection === 'desc';
    }
    get isSorted() {
        return this.isAscending || this.isDescending;
    }
    get isRightAligned() {
        return this.args.align === 'right';
    }
    initColumn = modifier(()=>{
        const { currentSort, name } = this.args;
        const { sortColumn, sortDirection } = currentSort ?? DEFAULT_COLUMN_OUTPUT;
        this.sortDirection = sortColumn === name ? sortDirection : null;
    });
    /**
   * This is the reverse sort behavior. It will sort descending, then ascending,
   * then back to unsorted.
   */ handleReverseSort() {
        if (this.isDescending) {
            this.sortDirection = 'asc';
        } else if (this.isAscending) {
            this.sortDirection = null;
        } else {
            this.sortDirection = 'desc';
        }
    }
    /**
   * This is the default sort behavior. It will sort ascending, then descending,
   * then back to unsorted.
   */ handleNormalSort() {
        if (this.isAscending) {
            this.sortDirection = 'desc';
        } else if (this.isDescending) {
            this.sortDirection = null;
        } else {
            this.sortDirection = 'asc';
        }
    }
    @action
    columnClicked() {
        if (this.args.reverse) {
            this.handleReverseSort();
        } else {
            this.handleNormalSort();
        }
        this.args.onClick?.({
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection
        });
    }
    static{
        template_914399fb0aed41b1b2ff571767c91448(`
    <th
      scope='col'
      class='px-3 py-3.5 text-sm font-semibold text-gray-900 first:pl-4 first:pr-3 last:pl-3 last:pr-4 sm:first:pl-6 sm:last:pr-6
        {{if this.isRightAligned "text-right" "text-left"}}'
      {{this.initColumn}}
      ...attributes
    >
      {{#if this.isSortable}}
        <button
          data-test-id='table-col-btn'
          type='button'
          class='group inline-flex items-center gap-1'
          {{on 'click' this.columnClicked}}
        >
          {{yield}}

          <span
            class='flex-none rounded bg-purple-100 text-gray-900 transition group-hover:bg-purple-200'
          >
            {{#if this.isSorted}}
              <UiIcon
                data-test-id='{{if this.isAscending "sort-asc" "sort-desc"}}'
                class='transition {{if this.isAscending "-rotate-180"}}'
                @icon='chevron-down'
                @type='mini'
              />
            {{/if}}
          </span>
        </button>
      {{else}}
        {{yield}}
      {{/if}}
    </th>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
interface UiTableHeadSignature {
    Element: HTMLElement;
    Args: {
        currentSort?: ColumnOutput;
        onColumnClick?: (output: ColumnOutput) => unknown;
    };
    Blocks: {
        default: [{
                Th: WithBoundArgs<typeof UiTableHeadCell, 'currentSort' | 'onClick'>;
            }];
    };
}
const UiTableHead: TOC<UiTableHeadSignature> = template_914399fb0aed41b1b2ff571767c91448(`
  <thead class='bg-purple-50'>
    <tr>
      {{yield
        (hash
          Th=(component
            UiTableHeadCell currentSort=@currentSort onClick=@onColumnClick
          )
        )
      }}
    </tr>
  </thead>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export interface UiTableSignature {
    Element: HTMLDivElement;
    Args: {
        isLoading?: boolean;
        pageMeta?: PageMeta;
        onPageChanged?: (page: number) => unknown;
    };
    Blocks: {
        default: [{
                Body: WithBoundArgs<typeof UiTableBody, 'isLoading'>;
                Head: WithBoundArgs<typeof UiTableHead, never>;
            }];
    };
}
const UiTableComponent: TOC<UiTableSignature> = template_914399fb0aed41b1b2ff571767c91448(`
  <div class='flow-root' ...attributes>
    <div class='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
      <div class='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
        <div
          class='relative z-0 overflow-hidden shadow ring-1 ring-gray-300 sm:rounded-lg'
        >
          <table
            class='min-w-full divide-y divide-gray-300
              {{if @isLoading "min-h-[6rem]"}}'
          >
            {{yield
              (hash
                Body=(component UiTableBody isLoading=@isLoading)
                Head=(component UiTableHead)
              )
            }}
          </table>

          {{#if @pageMeta}}
            <div class='border-t border-gray-200 bg-white px-4 py-3 sm:px-6'>
              <UiPagination
                @pageMeta={{@pageMeta}}
                @onPageChange={{@onPageChanged}}
              />
            </div>
          {{/if}}
        </div>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiTableComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiTable: typeof UiTableComponent;
    }
}
