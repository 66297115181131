import { template as template_c5fb3b1afab4464688e4f72162d2ef93 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
const BanIcon: TOC<{
    Element: SVGElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}> = template_c5fb3b1afab4464688e4f72162d2ef93(`
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    fill='none'
    stroke='none'
    aria-hidden='true'
    ...attributes
  >
    <path
      fill='currentColor'
      d='M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z'
    />
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BanIcon;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        BanIcon: typeof BanIcon;
    }
}
