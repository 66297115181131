import { template as template_77414c136a8d4bb2a9ab897df1f62c2b } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { fn } from '@ember/helper';
import UiModal from './ui-modal';
import UiButton from '@onwardcare/ui-components/components/ui-button';
import UiForm from '@onwardcare/ui-components/components/ui-form';
import UiIcon from '@onwardcare/ui-components/components/ui-icon';
export interface UiConfirmationSignature {
    Element: HTMLDivElement;
    Args: {
        cancelText?: string;
        confirmText?: string;
        isOpen: boolean;
        onCancel: () => unknown;
        onConfirm: (close: () => unknown) => unknown;
    };
    Blocks: {
        body: [];
        title: [];
    };
}
const UiConfirmation: TOC<UiConfirmationSignature> = template_77414c136a8d4bb2a9ab897df1f62c2b(`
  <UiModal
    data-test-id='confirmation-dialog'
    @isOpen={{@isOpen}}
    @onClose={{@onCancel}}
  >
    <:body>
      <div class='sm:flex sm:items-start'>
        <div
          class='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'
        >
          <UiIcon
            class='h-8 w-8 text-red-600 sm:h-6 sm:w-6'
            @icon='exclamation-triangle'
          />
        </div>
        <div class='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
          <h3 class='text-base font-semibold leading-6 text-gray-900'>
            {{yield to='title'}}
          </h3>

          <div class='mt-2 text-sm text-gray-500'>
            {{yield to='body'}}
          </div>
        </div>
      </div>
    </:body>
    <:footer as |Footer|>
      <UiForm
        @noStyles={{true}}
        @onSubmit={{fn @onConfirm Footer.close}}
        as |Form|
      >
        <Form.Actions
          class='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'
          as |Actions|
        >
          <Actions.Submit
            data-test-id='confirm-yes-btn'
            class='w-full justify-center sm:ml-3 sm:w-auto'
            @variant='danger'
          >
            {{if @confirmText @confirmText 'Yes'}}
          </Actions.Submit>
          <UiButton
            data-test-id='confirm-no-btn'
            class='mt-3 w-full justify-center sm:mt-0 sm:w-auto'
            @onClick={{Footer.close}}
          >
            {{if @cancelText @cancelText 'No'}}
          </UiButton>
        </Form.Actions>
      </UiForm>
    </:footer>
  </UiModal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UiConfirmation;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiConfirmation: typeof UiConfirmation;
    }
}
