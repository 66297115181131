import { template as template_10c25b8f11e049258a008eb693b5bd8d } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicPieChart from '../basic-pie-chart';
export interface VolumeByPayerTypeSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const VolumeByPayerType: TOC<VolumeByPayerTypeSignature> = template_10c25b8f11e049258a008eb693b5bd8d(`
  <BasicPieChart
    @modelName='volume-by-payer-type'
    @title='Volume by Payer Type'
    @tooltipText='Distribution of trips by Payer Type.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default VolumeByPayerType;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::VolumeByPayerType': typeof VolumeByPayerType;
    }
}
