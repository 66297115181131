import { template as template_389b1f4aa7fe47779ef29cc1f8b5101e } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface VolumeByDistanceSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const VolumeByDistance: TOC<VolumeByDistanceSignature> = template_389b1f4aa7fe47779ef29cc1f8b5101e(`
  <BasicColumnChart
    @modelName='volume-by-distance'
    @stacking='normal'
    @title='Volume by Distance'
    @tooltipText='Trip ordering trends segmented by distance. Long distance is defined as any trip whose distance is greater than 30 miles.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default VolumeByDistance;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::VolumeByDistance': typeof VolumeByDistance;
    }
}
