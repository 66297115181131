import { template as template_76af95e85ebc4c659ebac41bb19e0a3c } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface JuicedPercentageSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const JuicedPercentage: TOC<JuicedPercentageSignature> = template_76af95e85ebc4c659ebac41bb19e0a3c(`
  <BasicColumnChart
    @format='percent'
    @modelName='juiced-percentage'
    @title='Percent of Rides Juiced'
    @tooltipText='Percent of rides that were juiced of total rides completed with a start date in the date range.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default JuicedPercentage;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::JuicedPercentage': typeof JuicedPercentage;
    }
}
