import { template as template_6a73525910df46e3bae8da56b63d3237 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type { EmptyObject } from '@ember/component/helper';
import { fn } from '@ember/helper';
import { eq } from 'ember-truth-helpers';
import { formatNumber } from '../utils/number-format';
import UiButton from '@onwardcare/ui-components/components/ui-button';
export interface PageMeta {
    currentPage?: number;
    nextPage?: number | null;
    perPage?: number;
    prevPage?: number | null;
    totalCount?: number;
    totalPages?: number;
}
export interface UiPaginationSignature {
    Element: HTMLElement;
    Args: {
        pageMeta?: PageMeta;
        onPageChange?: (page: number) => unknown;
    };
    Blocks: EmptyObject;
}
export default class UiPaginationComponent extends Component<UiPaginationSignature> {
    get currentPage(): number {
        return this.args.pageMeta?.currentPage ?? 1;
    }
    get perPage(): number {
        return this.args.pageMeta?.perPage ?? 0;
    }
    get totalPages(): number {
        return this.args.pageMeta?.totalPages ?? 0;
    }
    get totalCount(): number {
        return this.args.pageMeta?.totalCount ?? 0;
    }
    get startRecord() {
        return this.totalCount === 0 ? 0 : this.currentPage * this.perPage - this.perPage + 1;
    }
    get endRecord() {
        const end = this.currentPage * this.perPage;
        return end > this.totalCount ? this.totalCount : end;
    }
    get prevPage() {
        return this.args.pageMeta?.prevPage ?? null;
    }
    get nextPage() {
        return this.args.pageMeta?.nextPage ?? null;
    }
    @action
    pageChanged(page: number | null): void {
        if (page !== null) {
            this.args.onPageChange?.(page);
        }
    }
    static{
        template_6a73525910df46e3bae8da56b63d3237(`
    <nav
      class='flex items-center justify-between'
      aria-label='Pagination'
      ...attributes
    >
      {{#if @pageMeta}}
        <div class='hidden sm:block'>
          <p data-test-id='page-meta' class='text-sm text-gray-700'>
            Showing
            <span data-test-id='page-start' class='font-medium'>
              {{formatNumber this.startRecord}}
            </span>
            to
            <span data-test-id='page-end' class='font-medium'>
              {{formatNumber this.endRecord}}
            </span>
            of
            <span data-test-id='page-results' class='font-medium'>
              {{formatNumber this.totalCount}}
            </span>
            results
          </p>
        </div>
      {{/if}}

      <div class='flex flex-1 justify-between gap-3 sm:justify-end'>
        <UiButton
          data-test-id='previous-btn'
          @icon='chevron-left'
          @size='small'
          @onClick={{fn this.pageChanged this.prevPage}}
          @disabled={{eq this.prevPage null}}
        >
          Previous
        </UiButton>

        <UiButton
          data-test-id='next-btn'
          @icon='chevron-right'
          @iconPosition='trailing'
          @size='small'
          @onClick={{fn this.pageChanged this.nextPage}}
          @disabled={{eq this.nextPage null}}
        >
          Next
        </UiButton>
      </div>
    </nav>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        UiPagination: typeof UiPaginationComponent;
    }
}
