import { template as template_023fc68c028d44c1904c168729514971 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface OnTimeRatesSignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const OnTimeRates: TOC<OnTimeRatesSignature> = template_023fc68c028d44c1904c168729514971(`
  <BasicColumnChart
    @format='percent'
    @modelName='on-time-percentage'
    @title='Percent On Time'
    @tooltipText='Percent of completed trips that started within 15 minutes of the scheduled start time in the date range.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OnTimeRates;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::OnTimeRates': typeof OnTimeRates;
    }
}
