import { template as template_ce4fc65a1e1e40e88566324e145dfd13 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type { EmptyObject } from '@ember/component/helper';
import BasicColumnChart from '../basic-column-chart';
export interface VolumeByTimeOfDaySignature {
    Element: HTMLDivElement;
    Args: EmptyObject;
    Blocks: EmptyObject;
}
const VolumeByTimeOfDay: TOC<VolumeByTimeOfDaySignature> = template_ce4fc65a1e1e40e88566324e145dfd13(`
  <BasicColumnChart
    @modelName='volume-by-time-of-day'
    @stacking='normal'
    @title='Volume by Time of Day'
    @tooltipText='Count of requested trips within the date range grouped by hour of day.'
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default VolumeByTimeOfDay;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Reporting::VolumeByTimeOfDay': typeof VolumeByTimeOfDay;
    }
}
